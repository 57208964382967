@font-face {
  font-family: "LibreFranklin"; /* Nom de la police */
  src: url("./assets/fonts/LibreFranklin-Regular.ttf") format("truetype"); /* Chemin vers le fichier de police */
}

@font-face {
  font-family: "FontFamily"; /* Nom de la police */
  src: url("./assets/fonts/fa-brands-400.woff2") format("woff2"); /* Chemin vers le fichier de police */
}

@font-face {
  font-family: "Butler"; /* Nom de la police */
  src: url("./assets/fonts/Butler_Ultra_Light.otf") format("opentype"); /* Chemin vers le fichier de police */
}

@font-face {
  font-family: "Butler-light"; /* Nom de la police */
  src: url("./assets/fonts/Butler_Light.otf") format("opentype"); /* Chemin vers le fichier de police */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 40px; /* Taille de police de 32 pixels */
  font-family: "Butler-light", sans-serif;
  text-align: center;
  line-height: 1.2em;
  color: #3c3c3c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
