/* About.style.css */
.container {
  width: 80%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbfbfb;
  flex-direction: column;
}

.trait {
  width: 80%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 10px;
  margin-bottom: 20px;
}

.text {
  font-size: 16px; /* Taille de police de 32 pixels */
  font-family: Georgia, sans-serif;
  text-align: justify;
  color: #3c3c3c;
  line-height: 1.5em;
}

.booking-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.calendar-section {
  flex: 1;
  margin-right: 20px;
  padding: 20px;
  align-items: center;
  justify-content: space-around;
}

.form-section {
  flex: 2;
}

form {
  margin: 0 auto;
}

label {
  display: block;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 16px; /* Taille de police de 32 pixels */
  font-family: Georgia, sans-serif;
  text-align: justify;
  color: #3c3c3c;
  line-height: 1.5em;
}

input[type="text"],
input[type="email"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fbfbfb; /* Couleur de fond */
  border: none; /* Supprime la bordure par défaut */
  border-bottom: 1px solid #ababab; /* Ajoute la bordure en bas */
  box-sizing: border-box;
  border-left: 0.5px solid #fbfbfb; /* Ajoute la bordure à gauche */
  border-right: 0.5px solid #fbfbfb; /* Ajoute la bordure à droite */
  border-top: 0.5px solid #fbfbfb; /* Ajoute la bordure en haut */
  height: 50px;
  transition: border 0.3s ease;
  font-size: 14px; /* Taille de police de 32 pixels */
  font-family: Georgia, sans-serif;
  text-align: justify;
  color: #3c3c3c;
  line-height: 1.5em;
}

/* Lorsque le champ est en focus */
input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus {
  outline: none; /* Supprime la bordure de focus par défaut */
  border-left: 0.5px solid #ababab; /* Ajoute la bordure à gauche */
  border-right: 0.5px solid #ababab; /* Ajoute la bordure à droite */
  border-top: 0.5px solid #ababab; /* Ajoute la bordure en haut */
}

select option {
  font-size: 14px; /* Taille de police de 32 pixels */
  font-family: Georgia, sans-serif;
  text-align: justify;
  color: #3c3c3c;
  line-height: 1.5em;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
