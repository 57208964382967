.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fbfbfb;
}

.logo {
  margin-bottom: 10px; /* Espacement entre le logo et la barre de navigation */
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #fbfbfb;
  padding-top: 10px;
}

.logoImg {
  width: 120px;
  height: 120px;
}

.selected {
  color: #70b1b0 !important;
  font-weight: 700;
}

.navbar {
  display: flex;
  justify-content: space-around;
  width: 60%;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  letter-spacing: 1px;
  line-height: 24px;
  width: 100%;
}

/* Style pour les liens dans la barre de navigation */
.navbar ul li a {
  text-decoration: none;
  color: #666;
  transition: color 0.3s ease;
}

.navbar ul li a:hover {
  color: black; /* Couleur au survol */
}

/* Style pour les liens dans la barre de navigation */
.navbar ul li {
  text-decoration: none;
  color: #666;
  transition: color 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: "LibreFranklin", sans-serif;
}

.navbar ul li:hover {
  color: black; /* Couleur au survol */
}
/* Style pour le menu déroulant */
.portfolio .dropdown {
  display: none;
  position: absolute;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  width: 200px;
}

.arrow-down::after {
  content: "\25BC"; /* Caractère Unicode de la flèche vers le bas */
  display: inline-block;
  margin-left: 0px; /* Marge à gauche pour l'espacement */
  font-size: 9px; /* Taille de la police */
}

.portfolio:hover .dropdown {
  display: block;
}

.portfolio .dropdown li {
  height: 50px;
  width: 100%;
  align-items: center;
  display: flex;
  padding-left: 10px;
}

.portfolio .dropdown li:hover {
  background-color: #f5f5f5;
}

.trait {
  width: 80%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 30px;
}

/* Ajustements pour une meilleure expérience utilisateur sur mobile */
@media (max-width: 800px) {
  .navbar ul {
    flex-direction: column;
    align-items: center;
  }

  .navbar ul li {
    margin: 10px 0;
  }
}
