.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Au-dessus de tout */
}

.arrow-button {
  font-size: 30px;
  width: 60px;
  color: white;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10%;
}
.disabled {
  background-color: transparent;
  color: transparent;
  transition: none;
}
.disabled:hover {
  background-color: transparent;
  color: transparent;
  transition: none;
  cursor: auto;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}
