.home-section {
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fbfbfb;
  width: 100%;
}

.image-container {
  position: relative;
  width: calc(32%);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Couleur de fond sombre semi-transparente */
  opacity: 0; /* Initialement transparent */
  transition: opacity 0.5s, transform 1s;
}

.image-container:hover .image-overlay {
  opacity: 1;
  transform: scale(1.03);
}

.home-image {
  width: 100%;
  height: 75vh;
  min-height: 280px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 1s;
}

.image-label {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  opacity: 0;
  font-size: 3vw;
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  transition: opacity 0.5s, transform 1s;
}

.image-container:hover .home-image {
  transform: scale(1.03);
}

.image-container:hover .image-label {
  opacity: 1;
  transform: translate(-50%, 0%);
}

.portfolio-link {
  position: absolute;
  bottom: 40%; /* Positionnement au milieu du conteneur parent */
  left: 50%; /* Positionnement au milieu du conteneur parent */
  transform: translate(-50%, 50%); /* Décalage pour centrer verticalement */
  opacity: 0; /* Initialement transparent */
  font-size: 13px; /* Taille du texte */
  color: black; /* Couleur du texte */
  background-color: white; /* Couleur de fond semi-transparente */
  padding: 15px; /* Espacement interne */
  font-weight: 600;
  transition: opacity 0.5s, transform 1s; /* Transition d'opacité et de transformation */
}

.image-container:hover .portfolio-link {
  opacity: 1; /* Afficher la boîte blanche au survol */
  transform: translate(-50%, 0%); /* Décalage pour une apparence plus basse */
}

/* Ajustements pour une meilleure expérience utilisateur sur mobile */
@media (max-width: 800px) {
  .home-section {
    flex-direction: column;
    align-items: center;
  }
  .image-container {
    width: calc(95%);
    margin-bottom: 30px;
  }

  .image-label {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    opacity: 0;
    font-size: 50px;
  }

  .navbar ul li {
    margin: 10px 0;
  }
}
