.tarif-section {
  margin-bottom: 30px;
}

.tarif-section h2 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

table th {
  background-color: #f4f4f4;
}
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fbfbfb;
  padding: 50px;
  width: 50%;
}

.text {
  font-size: 16px; /* Taille de police de 32 pixels */
  font-family: Georgia, sans-serif;
  text-align: justify;
  color: #3c3c3c;
  line-height: 1.5em;
}

.contact-info,
.social-media {
  width: 45%;
}

.contact-info h2,
.social-media h2 {
  margin-bottom: 10px;
}

.contact-info ul,
.social-media ul {
  list-style: none;
  padding: 0;
}

.contact-info ul li,
.social-media ul li {
  margin-bottom: 5px;
}

.social-media ul li a {
  text-decoration: none;
  color: #333;
}

.social-media ul li a:hover {
  color: #007bff;
}
.info-section {
  margin-top: 30px;
  text-align: left;
}

.info-section h2 {
  margin-bottom: 10px;
  color: #333;
}

.info-section p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #555;
}

.info-section strong {
  color: #222;
}