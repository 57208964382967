.mariages-page {
  /* Styles pour la page des mariages */
  padding: 20px;
  background-color: #fbfbfb;
}

.mariages-header {
  text-align: center; /* Centrer le contenu */
  margin-bottom: 20px; /* Espacement sous l'en-tête */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-masonry-grid {
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer;
}

.image:hover {
  transform: scale(1.02); /* Grossissement au survol */
  transition: transform 0.3s ease-in-out;
}
.sub-folder-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  letter-spacing: 1px;
  line-height: 24px;
  width: 60%;
  max-width: 70%;
}

.sub-folder-list li {
  text-decoration: none;
  color: #666;
  transition: color 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: "LibreFranklin", sans-serif;
}

.sub-folder-list li.active {
  font-weight: bold;
  color: #70b1b0 !important;
}

.bounce-in {
  animation: bounceIn 1s ease-in-out;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Ajustements pour une meilleure expérience utilisateur sur mobile */
@media (max-width: 800px) {
  .sub-folder-list {
    flex-direction: column;
    align-items: center;
  }

  .sub-folder-list li {
    margin: 10px 0;
  }
}
