/* About.style.css */
.about-page {
  padding: 20px;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbfbfb;
  flex-direction: column;
}

.intro-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  padding: 30px;
  width: 80%;
}

.intro-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 45%;
}

.profile-image {
  width: 45%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.profile-image img {
  width: 100%;
  border-radius: 2px;
}

.services-section,
.journey-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titre {
  font-size: 34px; /* Taille de police de 32 pixels */
  font-family: "Butler-light", sans-serif;
  text-align: center;
  line-height: 1.2em;
  color: #3c3c3c;
}

.text {
  font-size: 16px; /* Taille de police de 32 pixels */
  font-family: Georgia, sans-serif;
  text-align: justify;
  color: #3c3c3c;
  line-height: 1.5em;
}

.trait {
  width: 80%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top : 10px;
  margin-bottom: 20px;
}