.footer {
  text-align: center;
  padding-bottom: 20px;
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.social-icons {
  margin-bottom: 10px;
}

.social-icons a {
  color: #333;
  font-size: 24px;
  margin: 0 10px;
}

.social-icons a:hover {
  color: #007bff; /* Couleur de surbrillance */
}

.footer .copyright {
  font-size: 14px;
  color: #777;
}

.trait {
  width: 80%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 30px;
}
